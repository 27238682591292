<template lang="pug">
.page
  el-card.tableCard(v-loading="loading")
    template(#header)
      span {{evaluationInfo.name}} 的题库，当前共 {{list.length}} 条
      .rightAction(style="float:right")
        el-button(type="success" size="mini" icon="el-icon-plus"  @click="handleAdd") 添加新题目
        el-button(type="primary" size="mini" icon="el-icon-sort"  @click="handleReorder") 整理排序
    el-table(:data="list")
      el-table-column(prop="_id" label="id")
      el-table-column(prop="title" label="题目")
      el-table-column(prop="desc" label="说明")
      el-table-column(label="答案")
        template(#default="scope")
          el-tag {{ scope.row?.answerList?.length }}
      el-table-column(label="排序")
        template(#default="scope")
          el-tag() {{ scope.row?.order }}
      el-table-column(fixed="right" label="操作" width="200")
        template(#default="scope")
          el-button(type="primary" icon="el-icon-edit" size="mini" plain @click="handleEdit(scope.row)") 编辑
          el-popconfirm(title="确定要删除吗？" @confirm="handleDelete(scope.row)")
            template(#reference)
              el-button(type="danger" icon="el-icon-delete" size="mini" plain) 删除

  el-dialog(v-model="isShowEditDialog")
    el-form(label-width="6em")
      el-form-item(label="id" v-if="editFormData._id")
        el-input(v-model="editFormData._id" disabled)
      el-form-item(label="题目")
        el-input(v-model="editFormData.title")
      el-form-item(label="说明")
        el-input(v-model="editFormData.desc")
      template(v-for="(i,n) in editFormData.answerList")
        el-form-item(:label="`答案 - ${n+1}`")
          el-form-item(label="内容")
            el-input(v-model="i.text")
          el-form-item(label="计分值")
            el-input(v-model="i.value")
      el-form-item
        el-button(type="success" @click="handleAddAnswerItem") 添加一条答案
      el-form-item
        el-button(type="primary" @click="handleSubmitEdit") 保存
</template>

<script setup>
import { inject, onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'

const route = useRoute()
const { fetch, router, message } = inject('global')

const loading = ref(false)
const list = ref([])
const evaluationInfo = ref({
  name: ''
})

const isShowEditDialog = ref(false)
const editFormData = ref({
  _id: '',
  title: '',
  desc: '',
  answerList: []
})

function initData() {
  list.value = []
  getList()
}
function getList() {
  loading.value = true
  let params = {
    sort: 'order',
    evaluation: route.params.id
  }
  fetch
    .get('/evaluation/question', { params })
    .then(res => {
      list.value = res.list
      evaluationInfo.value = res.evaluationInfo
    })
    .finally(() => {
      loading.value = false
    })
}

function handleReorder() {
  fetch
    .put(`/evaluation/question/reorder`, {
      evaluation: route.params.id
    })
    .then(() => {
      initData()
    })
}
function handleDelete(val) {
  fetch.delete(`/evaluation/question/${val._id}`).then(() => {
    initData()
  })
}
function handleAdd() {
  editFormData.value = {
    _id: '',
    title: '',
    desc: '',
    answerList: [{ text: '', value: '' }]
  }
  isShowEditDialog.value = true
}
function handleEdit(val) {
  editFormData.value = val
  isShowEditDialog.value = true
}
function handleAddAnswerItem() {
  editFormData.value.answerList.push({ text: '', value: '' })
}
function handleSubmitEdit() {
  fetch
    .put(`/evaluation/question`, {
      ...editFormData.value,
      evaluation: route.params.id
    })
    .then(res => {
      message.success(res?.msg || '保存成功')
      isShowEditDialog.value = false
      initData()
    })
    .catch(err => {
      message.error(err?.msg || err || '系统异常')
    })
}

onMounted(() => {
  initData()
})
</script>

<style lang="less" scoped>
.tableCard {
  .el-table {
    min-height: 600px;
  }
}
.footer {
  text-align: center;
  padding-top: 10px;
}
</style>
